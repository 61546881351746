<template>
  <div class="page-slideout">
    <div class="section section-separate">
      <div class="container">
        <form class="content register-content">
          <div class="nav-responsive">
            <ul class="nav nav-register">
              <template v-for="(item, index) in itemsStep">
                <li :key="index" :class="item.active && 'active'">
                  <a href="" class="d-flex flex-column">
                    <span v-if="item.finish" class="icons"
                      ><img src="@/assets/icons/icon-checkmark.svg" alt=""
                    /></span>
                    <span class="text">{{ item.name }}</span>
                  </a>
                </li>
              </template>
            </ul>
          </div>

          <div class="scroll-wrapper scrollbar-inner has-menu overflow-y-auto">
            <div class="form-box w-550 no-scrllbar">
              <div class="form-group">
                <h3 class="title-md mb-2 title-green">
                  {{ $t("SignupAfterStep7.title") }}
                </h3>
                <label class="light mb-0">{{
                  $t("SignupAfterStep7.label1")
                }}</label>
              </div>

              <div class="form-group pt-2">
                <label class="light">{{ propertyName }}</label>
                <h3 class="f20 mb-3">{{ $t("SignupAfterStep7.label2") }}</h3>

                <label class="light">
                  {{ $t("SignupAfterStep7.label3") }}
                </label>
              </div>

              <div class="form-group">
                <h3 class="f20 mb-3">
                  {{ $t("SignupAfterStep7.label4") }} {{ cd_min }}:{{ cd_sec }}
                </h3>

                <label class="light">
                  {{ $t("SignupAfterStep7.label5") }}
                </label>
              </div>

              <div class="buttons">
                <button
                  class="btn w-100 rounded-5 gl-bt hover_btn"
                  type="button"
                  @click="gotoPassLogin()"
                  :disabled="!canGoManage"
                >
                  <span>{{ $t("SignupAfterStep7.btn_gotoaccount") }}</span>
                </button>
              </div>
            </div>
          </div>
        </form>
        <div class="sidebar">
          <div class="inner d-flex flex-column">
            <div v-if="maxproperty > 1" class="form-group">
              <div class="d-flex">
                <h3 class="f20 mb-3">
                  {{ $t("SignupAfterStep7.title_slideright") }}
                </h3>
                <span
                  v-if="maxproperty !== 1"
                  class="number light title-gray ml-auto pl-3 pt-1"
                  >{{ allproperty }}/{{ maxproperty }}</span
                >
              </div>
              <p class="light mb-0">
                {{ $t("SignupAfterStep7.label_slideright1") }}
                {{ allproperty }}
                {{ $t("SignupAfterStep7.unit_label_slideright1") }}
                {{ $t("SignupAfterStep7.label_slideright2") }}
              </p>

              <div v-if="maxproperty !== 1" class="buttons">
                <button
                  v-if="cangoupdatenextproperty"
                  class="btn w-100 rounded-5 btn-addtype mb-3"
                  type="button"
                  @click="gotoupdatenextproperty()"
                >
                  <span
                    >{{ $t("SignupAfterStep7.btn_updatenextproperty") }}:
                    {{ nextpropertyno }}</span
                  >
                </button>

                <button
                  v-if="allproperty < maxproperty"
                  class="btn w-100 rounded-5 btn-addtype"
                  type="button"
                  @click="gotoaddproperty()"
                  :disabled="!canAddNewProperty"
                >
                  <span>{{ $t("SignupAfterStep7.btn_addnewproperty") }}</span>
                </button>
              </div>
            </div>
            <a class="img-exemple iphone-model">
              <img src="@/assets/img/thumb/Congratulation.gif" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import swal from "sweetalert";
import _ from "lodash";

export default {
  data: () => ({
    stepwh_signup: 8,
    propertyID: null,
    propertyName: "",
    propertyTempType: "",
    propertyType: "",
    itemsStep: [],
    keepToken: "",
    keepUserID: "",
    canAddNewProperty: false,
    countdown: 120,
    cd_timer: "2:00",
    cd_min: "2",
    cd_sec: "00",
    canAddNewPropertyByCount: true,
    stepthreecreate: false,
    canGoManage: false,
    allproperty: 0,
    maxproperty: 0,
    userToken: "",
    userEmail: "",
    propertyOfUser: [],
    itemsPropertyType: [],
    cangoupdatenextproperty: false,
    nextpropertyno: 2,
    nextpropertyid: "",
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    if (self.userToken && self.propertyID) {
      self.initRegisterData();
    } else {
      self.gotosignin();
    }
    if (
      (localStorage.getItem("Token") == "" || !localStorage.getItem("Token")) &&
      self.userToken
    ) {
      localStorage.setItem("Token", self.userToken);
    }
    localStorage.setItem("signupstep", self.stepwh_signup);
    localStorage.setItem("SignupStep", self.stepwh_signup);
    self.keepToken =
      localStorage.getItem("Token") ||
      self.$store.state.resSignUpData.access_token;
    self.countDownTimer();
  },
  mounted() {
    EventBus.$on("refreshRightPhoto", this.initRegisterData);
    this.colorHover();
  },
  watch: {
    countdown() {
      const self = this;
      // console.log('countdown: ', self.countdown)
      if (Number(self.countdown) == 0) {
        self.canAddNewPropertyByCount = true;
        // self.gotoSignupFunc();
      } else self.canAddNewPropertyByCount = false;
    },
    itemsPropertyType() {
      const self = this;
      const temp = self?.itemsPropertyType?.find(
        (p) => p.name == self.propertyTempType
      );
      // console.log('temp: ', temp)
      self.propertyType = temp?.value || null;
    },
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    countDownTimer() {
      if (Number(this.cd_min) == 0 && Number(this.cd_sec) == 0) {
        this.canGoManage = true;
        this.gotoPassLogin();
      } else this.canGoManage = true;
      setTimeout(() => {
        this.countdown -= 1;
        this.cd_sec -= 1;
        if (this.cd_min < 0) return;
        else if (this.cd_min < 1 && this.cd_sec < 1)
          return (this.canGoManage = true);
        else if (this.cd_sec < 0 && this.cd_min != 0) {
          this.cd_min -= 1;
          this.cd_sec = 59;
        } else if (this.cd_sec < 10 && this.cd_sec.length != 2)
          this.cd_sec = "0" + this.cd_sec;
        this.countDownTimer();
      }, 1000);
    },
    initRegisterData() {
      const self = this;
      self.initProperty();
      self.initStepData();
      self.initFirstGetUser();
    },
    gotosignin() {
      var item = { link: "signin", name: "SigninScreen" };
      EventBus.$emit("changePathname", item);
    },
    async initProperty() {
      const self = this;
      var temp = [];
      self.itemsPropertyType = [];
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + "/setting/property_type",
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage.getItem("language") || self.$store.state.language,
            },
          }
        );
        if (res.status == 200) {
          res.data.result.map((el) => {
            if (el?.value && (el?.description || el?.desciption))
              temp.push({
                _id: el._id,
                name: el.value || "",
                value: el._id,
                description:
                  el?.description ||
                  el?.desciption ||
                  self.$t("SignupStep.no_property_type_description"),
              });
          });
          self.itemsPropertyType = temp;
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      }
    },
    async initStepData() {
      const self = this;
      var temp = {};
      EventBus.$emit("loadingPage");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/property?_id=${self.propertyID}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result[0];
          self.propertyName = temp?.name;
          self.propertyTempType = temp?.property_type;
          // console.log("temp: ", temp);
          self.keepUserID = res?.data?.result[0]?.user?._id;
          // console.log(self.keepUserID)
          if (res?.data?.result?.length) {
            self.itemsStep = [
              {
                name: self.$t("SignupStep.step1"),
                active: (self.stepwh_signup == 1 && true) || false,
                finish: temp?.step_signup?.step1 || false,
                linkItems: {
                  link: `signup/basic/${self.userToken}/${self.propertyID}`,
                  name: "SignupBasicInformationScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 1,
              },
              {
                name: self.$t("SignupStep.step2"),
                active: (self.stepwh_signup == 2 && true) || false,
                finish: temp?.step_signup?.step2 || false,
                linkItems: {
                  link: `signup/room/${self.userToken}/${self.propertyID}`,
                  name: "SignupRoomScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 2,
              },
              {
                name: self.$t("SignupStep.step3"),
                active: (self.stepwh_signup == 3 && true) || false,
                finish: temp?.step_signup?.step3 || false,
                linkItems: {
                  link: `signup/rateplan/${self.userToken}/${self.propertyID}`,
                  name: "SignupRatePlanScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 3,
              },
              {
                name: self.$t("SignupStep.step4"),
                active: (self.stepwh_signup == 4 && true) || false,
                finish: temp?.step_signup?.step4 || false,
                linkItems: {
                  link: `signup/facilities/${self.userToken}/${self.propertyID}`,
                  name: "SignupFacilitiesScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 4,
              },
              {
                name: self.$t("SignupStep.step5"),
                active: (self.stepwh_signup == 5 && true) || false,
                finish: temp?.step_signup?.step5 || false,
                linkItems: {
                  link: `signup/pictures/${self.userToken}/${self.propertyID}`,
                  name: "SignupPicturesScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 5,
              },
              {
                name: self.$t("SignupStep.step6"),
                active: (self.stepwh_signup == 6 && true) || false,
                finish: temp?.step_signup?.step6 || false,
                linkItems: {
                  link: `signup/policy/${self.userToken}/${self.propertyID}`,
                  name: "SignupPolicyScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 6,
              },
              {
                name: self.$t("SignupStep.step7"),
                active: (self.stepwh_signup == 7 && true) || false,
                finish: temp?.step_signup?.step7 || false,
                linkItems: {
                  link: `signup/payment/${self.userToken}/${self.propertyID}`,
                  name: "SignupPaymentScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 7,
              },
            ];
          }
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
        self.gotoSignupFunc();
      } finally {
        self.getStepData(self.itemsStep);
        self.getUserData();
      }
    },
    getStepData(items) {
      const self = this;
      self.itemsStep = items;
    },
    async getUserData() {
      const self = this;
      var indexofcurrentproperty = null;
      var indexofcurrentsubproperty = null;
      var currentsubpropertylength = 0;
      var currentsubpropertymax = 0;
      var currentsubpropertytype = null;
      var itemsubproperty = [];

      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/user?_id=${self.keepUserID}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          if (res?.data?.result[0]?.property?.length) {
            res?.data?.result[0]?.property?.map((el, index) => {
              const findProperty = el?.properties?.findIndex(
                (p) => p == self.propertyID
              );
              if (findProperty !== -1) {
                indexofcurrentproperty = index;
                indexofcurrentsubproperty = findProperty;
                itemsubproperty = el?.properties || [];
                currentsubpropertylength = el?.properties?.length || 0;
                currentsubpropertymax = el?.property_number || 0;
                currentsubpropertytype = el?.property_type || null;
              }
            });
          }
          self.allproperty = currentsubpropertylength;
          self.maxproperty = currentsubpropertymax;
          self.stepitems =
            currentsubpropertymax > 1
              ? `${currentsubpropertylength}/${currentsubpropertymax}`
              : "";
          self.canAddNewProperty =
            self.allproperty === self.maxproperty ? false : true;
          if (indexofcurrentsubproperty < currentsubpropertylength - 1) {
            self.cangoupdatenextproperty = true;
            self.nextpropertyno = indexofcurrentsubproperty + 2;
            self.nextpropertyid =
              itemsubproperty[indexofcurrentsubproperty + 1];
          } else self.cangoupdatenextproperty = false;
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      }
    },
    async initFirstGetUser() {
      const self = this;
      var temp = [];
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + "/my_user", {
          headers: {
            Authorization:
              self?.userToken ||
              localStorage?.getItem("Token") ||
              self?.$store?.state?.resSignUpData?.access_token,
            "X-Language-Code":
              localStorage?.getItem("language") ||
              self?.$store?.state?.language,
          },
        });
        if (res.status == 200) {
          self.userEmail = res?.data?.result?.email;

          res?.data?.result?.property?.forEach((item) => {
            const findProperty = item?.properties?.find(
              (p) => p == self.propertyID
            );
          });

          self.propertyOfUser = res?.data?.result?.property[0];
          localStorage.setItem("userEmail", self.userEmail);
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      }
    },
    async initGetUser(propertyId) {
      const self = this;
      var temp = [];
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + "/my_user", {
          headers: {
            Authorization:
              self?.userToken ||
              localStorage?.getItem("Token") ||
              self?.$store?.state?.resSignUpData?.access_token,
            "X-Language-Code":
              localStorage?.getItem("language") ||
              self?.$store?.state?.language,
          },
        });
        if (res.status == 200) {
          self.userEmail = res?.data?.result?.email;
          self.propertyOfUser = res?.data?.result?.property[0];
          localStorage.setItem("userEmail", self.userEmail);
          self.sendVerify(propertyId);
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      }
    },
    async sendVerify(tempId) {
      const self = this;
      const numProperty = self?.propertyOfUser?.properties?.length || 1;
      const obj = {
        email: self?.userEmail || localStorage?.getItem("userEmail"),
        link: `${window.location.origin || process.env.VUE_APP_WEB}/signup/basic/${self.userToken}/${tempId}`,
        subject: `Information Property ${numProperty}`,
        button: "ADD PROPERTY",
      };
      var item = {
        link: `signup/basic/${self.userToken}/${tempId}`,
        name: "SignupBasicInformationScreen",
        params: { userToken: self.userToken, propertyId: tempId },
      };
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.post(
          process.env.VUE_APP_API + "/property/sent_mail_exit",
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res?.status == 200) {
          EventBus.$emit("changePathname", item);
          setTimeout(() => {
            window.location.reload();
          }, 300);
          EventBus.$emit("endloading");
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            res?.data?.message || self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    gotoaddproperty() {
      const self = this;
      var propertytype = self?.propertyOfUser?.property_type;
      if (propertytype) self.addPropertyNext(propertytype);
    },
    async addPropertyNext(type) {
      const self = this;
      const obj = {
        property_type: type,
      };
      var propertyId = null;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.post(
          process.env.VUE_APP_API + "/property/next",
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          EventBus.$emit("endloading");
          propertyId = res?.data?.result?._id;
          self.initGetUser(propertyId);
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    gofillmore(id) {
      var item = {
        link: `signup/basic/${id}`,
        name: "SignupBasicInformationScreen",
        params: { propertyId: id },
      };
      EventBus.$emit("changePathname", item);
    },
    gotoSignupFunc() {
      const item = { link: "signup", name: "SignupScreen" };
      // const item = { link: `signup/propertydetail/${self.propertyID}`, name: 'SignupPropertyDetailScreen', params: { propertyId: self.propertyID } }
      EventBus.$emit("changePathname", item);
    },
    gotoPassLogin() {
      const self = this;
      var itemManagement = {
        link: "provider/management/property",
        name: "SigninPropertyManagementScreen",
        params: { userToken: self.userToken, propertyId: self.propertyID },
      };
      EventBus.$emit("changePathname", itemManagement);
    },
    gotoupdatenextproperty() {
      const self = this;
      EventBus.$emit("loadingtillend");
      self.gofillmore(self.nextpropertyid);
      EventBus.$emit("endloading");
    },
  },
};
</script>

<style scoped>
.title-green {
  color: var(--green) !important;
}

.title-gray {
  color: var(--gray) !important;
}

.btn[disabled] {
  height: fit-content !important;
}
</style>

<style>
@media (min-width: 768px) and (max-width: 1024px) {
  .siq_bR {
    width: 3.5rem !important;
    height: 2rem !important;
    bottom: 7.5rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 3.5rem !important;
    height: 2rem !important;
    object-fit: contain !important;
  }
}
@media (min-width: 321px) and (max-width: 767px) {
  .siq_bR {
    width: 4.5rem !important;
    height: 3rem !important;
    bottom: 6rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 4.5rem !important;
    height: 3rem !important;
    object-fit: contain !important;
  }
}
@media (max-width: 320px) {
  .siq_bR {
    width: 3.5rem !important;
    height: 2rem !important;
    bottom: 6rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 3.5rem !important;
    height: 2rem !important;
    object-fit: contain !important;
  }
}
</style>
